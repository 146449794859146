<template>
  <div>
    <Frame title="Importar nóminas" :loading="loading">
      <Grid
        :data="data"
        :columns="columns"
        :actions="actions"
        addText="Importar nómina"
        :showAdd="$can('AddLoadData', 'STM')"
        @addNew="() => openModal()"
        @reloadData="getImports"
      />
    </Frame>
    <Modal :active.sync="showModal">
      <LoadDataForm :modelOrig="entity" />
    </Modal>
  </div>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';
import LoadDataForm from './LoadDataForm.vue';

export default {
  name: 'ImportData',
  components: {
    LoadDataForm,
  },
  data() {
    return {
      showModal: false,
      loading: false,
      entity: null,
      data: [],
      actions: [],
      columns: [
        {
          title: 'Nombre',
          field: 'LoadName',
        },
        {
          title: 'Fecha',
          field: 'CreationDate',
        },
        {
          title: 'Estatus',
          field: 'StatusCode',
          template: this.formatCode,
        },
      ],
    };
  },
  mounted() {
    this.getImports();
    this.actions.push({
      text: 'Ver',
      callback: this.addData,
    });
    // if (this.$can('EditLoadData', 'STM')) {
    //   this.actions.push({
    //     text: 'Editar',
    //     callback: this.openModal,
    //   });
    // }
    if (this.$can('DeleteLoadData', 'STM')) {
      this.actions.push({
        text: 'Eliminar',
        callback: this.deleteImport,
      });
    }
  },
  methods: {
    getImports() {
      this.loading = true;
      const apiClient = new ApiClient('file/loads/STM', 'common');
      apiClient.Get('?query=a').then((data) => {
        this.loading = false;
        this.data = data;
      });
    },
    deleteImport(item) {
      this.deleteItem(
        `file/loads/${item.LoadProcessID}/true`,
        '',
        '¿Desea eliminar la carga?',
        'Al Eliminar la carga se perderán los archivos subidos previamente.',
        'common'
      );
    },
    addData(item) {
      const id = item.LoadProcessID;

      this.$router.push({
        name: 'loadData',
        params: {
          id: id,
        },
      });
    },
    openModal(item = null) {
      this.entity = item;
      this.showModal = true;
    },
    formatCode(item) {
      const codes = [
        {
          code: 'COMPLETED',
          text: 'Completado',
          color: 'is-success',
        },
        {
          code: 'FAILED',
          text: 'Fallido',
          color: 'is-quinary',
        },
        {
          code: 'INPROC',
          text: 'En proceso',
          color: 'is-terciary-outlined',
        },
        {
          code: 'NEW',
          text: 'Nuevo',
          color: 'is-terciary',
        },
      ];

      const code = codes.find((x) => x.code === item.StatusCode);
      return `<span class="pw-tag ${code.color}">${code.text}</span>`;
    },
  },
};
</script>
