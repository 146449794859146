<template>
  <div>
    <Frame title="Importar nóminas" titleAction="Agregar">
      <Layout>
        <Column size="6">
          <Field label="Nombre">
            <Input
              :required="true"
              v-model="model.Name"
              :rules="{ max: 100 }"
              @keypress.enter="onSave"
              name="Name"
            />
          </Field>
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button @click="$parent.close()" color="terciary-outlined"> Cancelar </Button>
          <Button color="secondary" @click="onSave"> Guardar </Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';

export default {
  name: 'LoadDataForm',
  props: {
    modelOrig: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      model: {
        Name: '',
        applicationCode: 'STM',
      },
    };
  },
  mounted() {
    if (this.modelOrig) {
      this.model = {
        ...this.modelOrig,
        id: this.modelOrig.LoadProcessID,
        Name: this.modelOrig.LoadName,
      };
    }
  },
  methods: {
    async onSave() {
      const validate = await this.isValid();
      if (!validate) {
        return;
      }

      const apiClient = new ApiClient('file/loads', 'common');
      if (!this.model.LoadProcessID) {
        apiClient.Post(this.model).then(({ data }) => {
          if (data.LoadProcessID) {
            this.$router.push({
              name: 'loadData',
              params: {
                id: data.LoadProcessID,
              },
            });
          }
        });
      } else {
        apiClient.Put(this.model).then(() => {
          // console.log(data)
          this.$parent.close();
        });
      }
    },
  },
};
</script>
